const topButton: HTMLElement | null = document.querySelector('.top-button');

window.addEventListener('scroll', () => {
    const scrollTop = window.pageYOffset;
    scrollTop > 300 ? topButton?.classList.add('show') : topButton?.classList.remove('show');
});

topButton?.addEventListener('click', () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
});

export default null;
