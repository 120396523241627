const menuButton = document.querySelector('.header__menu-button');
const logo = document.querySelector('.header__logo');

const toggleMobileMenu = (opt: boolean): void => {
    const header = document.querySelector('.header');

    if (opt) {
        header?.classList.remove('header-collapse');
        logo?.classList.remove('header__logo--white');
        menuButton?.classList.remove('active');
        return;
    }

    header?.classList.toggle('header-collapse');
    logo?.classList.toggle('header__logo--white');
    menuButton?.classList.toggle('active');
};

menuButton?.addEventListener('click', () => toggleMobileMenu(false));

document
    .querySelectorAll('.header__menu a, .footer__menu a')
    .forEach(item => item.addEventListener('click', () => toggleMobileMenu(true)));

window.addEventListener('resize', () => {
    if (logo?.classList.contains('header__logo--white')) toggleMobileMenu(false);
});

export default null;
