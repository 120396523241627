import React from 'react';
import Modal from 'components/modal';
import store from 'store';
import { useObserver } from 'mobx-react';

const ContactUs: React.FC = () => {
    return useObserver(() => (
        <>
            <Modal
                showed={store.ui.contactUsModalShowed}
                onClose={(): void => store.ui.toggleContactUsModal()}
                modalClass="modalWhite"
                propsClass="contacts-modal__wrapper"
            >
                <div className="contacts-modal">
                    <div className="contacts-modal__title">
                        Opportunity Global Hispania S.L. <br />
                        opportunityservice.eu
                    </div>

                    <div className="contacts-modal__inform-block">
                        <div className="contacts-modal__inform-block-item">
                            <div className="contacts-modal__inform-block-item__left">
                                Sede y establecimiento:
                            </div>
                            <div className="contacts-modal__inform-block-item__right">
                                Avenida Diagonal, 376 - BJ, Barcelona, 08037
                            </div>
                        </div>
                        <div className="contacts-modal__inform-block-item nif">
                            <div className="contacts-modal__inform-block-item__left">NIF:</div>
                            <div className="contacts-modal__inform-block-item__right">
                                B05416318
                            </div>
                        </div>
                        <div className="contacts-modal__inform-block-item">
                            <div className="contacts-modal__inform-block-item__left">
                                Datos registrales:
                            </div>
                            <div className="contacts-modal__inform-block-item__right">
                                Inscrita en el Registro Mercantil de Barcelona, en el tomo 47 815,
                                folio 60 12/07/2021
                            </div>
                        </div>

                        <div className="contacts-modal__inform-block-item">
                            <div className="contacts-modal__inform-block-item__left">
                                <a className="link-phone" href="tel: 684 361 373">
                                    <span>684 361 373</span>
                                </a>
                            </div>
                            <div className="contacts-modal__inform-block-item__right">
                                <a className="link-email" href="mailto: info@opportunity.global">
                                    <span>info@opportunity.global</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="text-center">
                <button
                    type="button"
                    className="rb btn btn-default btn-shadow"
                    onClick={(): void => store.ui.toggleContactUsModal()}
                >
                    Contactenos
                </button>
            </div>
        </>
    ));
};

export default ContactUs;
