import 'regenerator-runtime/runtime';
import 'core-js';
import svg4everybody from 'svg4everybody';
import smoothscroll from 'smoothscroll-polyfill';
import { renderReactInto } from 'utils';
import WorldMap from 'components/map';
import store from 'store';
import LanguageOnPage from './language';
import TopSlider from './top-slider';
import GalleryModal from './gallery-modal';
import ContactUs from './contact-us';
import ServiceRequest from './service-request';
import RealEstate from './real-estate';
import './assets-under-managment';
import './menu';
import './router';
import '../../components/top-button';

document.querySelector('.main-banner__info-button')?.addEventListener('click', (): void => {
    store.ui.toggleGalleryModal(null, 'placement');
});

document.querySelectorAll('.mailto').forEach(el =>
    el.addEventListener('click', (): void => {
        store.ui.toggleContactUsModal();
    })
);

svg4everybody();
smoothscroll.polyfill();

renderReactInto('top-slider', TopSlider);
renderReactInto('gallery-modal', GalleryModal);
renderReactInto('world-map', WorldMap);
renderReactInto('contact-us', ContactUs);
renderReactInto('service-request', ServiceRequest);
renderReactInto('real-estate-gallery', RealEstate);
renderReactInto('lang-header', LanguageOnPage);
renderReactInto('lang-footer', LanguageOnPage);

export default null;
