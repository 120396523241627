import { createElement, ElementType, CElement } from 'react';
import ReactDOM from 'react-dom';

export const renderReactInto = (
    id: string,
    element: ElementType | CElement<any, any>,
    customEl?: HTMLElement
): void => {
    const wrapper = !customEl ? document.getElementById(id) : customEl.querySelector(`#${id}`);
    if (!wrapper) return;

    window.addEventListener('DOMContentLoaded', () => {
        ReactDOM.render(createElement(element as ElementType), wrapper);
    });
};
