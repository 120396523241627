import React, { useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import store from 'store';
import { useObserver } from 'mobx-react';

const TopSlider: React.FC = () => {
    const slider = useRef<Slider>(null);
    const speed = 3000;

    const settings: Settings = {
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: false,
        pauseOnHover: true,
        infinite: true,
        fade: true,
        speed,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return useObserver(() => (
        <Slider className="top-slider" {...settings} ref={slider}>
            {store.ui.placementImages.map(img => (
                <div key={`top-slider-${img.name}`}>
                    <div
                        className="top-slider__item"
                        style={{ backgroundImage: `url(${img.url})` }}
                    />
                </div>
            ))}
        </Slider>
    ));
};

export default TopSlider;
