import React, { useState } from 'react';
import LanguageSelector from 'components/language-selector';

const LanguageOnPage: React.FC = () => {
    const [currentReason, setCurrentReason] = useState<string | null>(null);

    const reasons: { value: string }[] = [{ value: 'En' }];

    return (
        <LanguageSelector
            items={reasons}
            selected={currentReason}
            placeholder="En"
            onSelect={setCurrentReason}
        />
    );
};

export default LanguageOnPage;
