export const slideToBottom = {
    from: {
        opacity: 0,
        transform: 'translateY(100%)'
    },
    enter: {
        opacity: 1,
        transform: 'translateY(0)'
    },
    leave: {
        opacity: 0,
        transform: 'translateY(100%)'
    }
};
