import React, { createRef, useRef, useState } from 'react';
import Modal from 'components/modal';
import store from 'store';
import { useObserver } from 'mobx-react';
import axios from 'axios';

const ServiceRequest: React.FC = () => {
    const [municipalities, setMunicipalities] = useState([]);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox4, setCheckbox4] = useState(false);

    const [municipality, setMunicipality] = useState(undefined);

    const onCheckbox2 = (): void => {
        setCheckbox2(!checkbox2);
    };

    const onCheckbox4 = (): void => {
        setCheckbox4(!checkbox4);
    };

    const onChangeProvince = (e: any): void => {
        setMunicipality(undefined);

        console.log(municipality);

        axios
            .get('https://sede.registradores.org/site/api/location/registralTowns', {
                params: {
                    province: e.target.value
                }
            })
            .then(res => {
                if (res.data) {
                    setMunicipalities(res.data);
                }
            });
    };

    return useObserver(() => (
        <>
            <Modal
                showed={store.ui.serviceRequestModalShowed}
                onClose={(): void => store.ui.toggleServiceRequestModal()}
                modalClass="modalYellow"
            >
                <form className="form-grid" action="/order/createOrder.php" method="POST">
                    <div className="container">
                        <div className="row">
                            <div className="col-4">
                                <div className="input-group">
                                    <label htmlFor="">Nombre y Apellidos</label>
                                    <input type="text" name="personName" required />
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="input-group">
                                    <label htmlFor="">E-mail</label>
                                    <input type="email" name="personEmail" required />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="modal-title-small">Dirección de la propiedad</div>
                                <div className="modal-description">
                                    Proporcione el mayor número de datos de que disponga para poder
                                    localizar la finca. Debe de rellenar al menos la dirección de la
                                    propiedad u otros datos de localización.
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <div className="input-group">
                                    <select
                                        name="addressProvinces"
                                        onChange={onChangeProvince}
                                        required
                                    >
                                        <option>Provincia</option>
                                        <option value="2">Albacete</option>
                                        <option value="3">Alicante/Alacant</option>
                                        <option value="4">Almería</option>
                                        <option value="1">Araba/Álava</option>
                                        <option value="33">Asturias</option>
                                        <option value="5">Ávila</option>
                                        <option value="6">Badajoz</option>
                                        <option value="7">Balears, Illes</option>
                                        <option value="8">Barcelona</option>
                                        <option value="48">Bizkaia</option>
                                        <option value="9">Burgos</option>
                                        <option value="10">Cáceres</option>
                                        <option value="11">Cádiz</option>
                                        <option value="39">Cantabria</option>
                                        <option value="12">Castellón/Castelló</option>
                                        <option value="51">Ceuta</option>
                                        <option value="13">Ciudad Real</option>
                                        <option value="14">Córdoba</option>
                                        <option value="15">Coruña, A</option>
                                        <option value="16">Cuenca</option>
                                        <option value="20">Gipuzkoa</option>
                                        <option value="17">Girona</option>
                                        <option value="18">Granada</option>
                                        <option value="19">Guadalajara</option>
                                        <option value="21">Huelva</option>
                                        <option value="22">Huesca</option>
                                        <option value="23">Jaén</option>
                                        <option value="24">León</option>
                                        <option value="25">Lleida</option>
                                        <option value="27">Lugo</option>
                                        <option value="28">Madrid</option>
                                        <option value="29">Málaga</option>
                                        <option value="52">Melilla</option>
                                        <option value="30">Murcia</option>
                                        <option value="31">Navarra</option>
                                        <option value="32">Ourense</option>
                                        <option value="34">Palencia</option>
                                        <option value="35">Palmas, Las</option>
                                        <option value="36">Pontevedra</option>
                                        <option value="26">Rioja, La</option>
                                        <option value="37">Salamanca</option>
                                        <option value="38">Santa Cruz de Tenerife</option>
                                        <option value="40">Segovia</option>
                                        <option value="41">Sevilla</option>
                                        <option value="42">Soria</option>
                                        <option value="43">Tarragona</option>
                                        <option value="44">Teruel</option>
                                        <option value="45">Toledo</option>
                                        <option value="46">Valencia/València</option>
                                        <option value="47">Valladolid</option>
                                        <option value="49">Zamora</option>
                                        <option value="50">Zaragoza</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="input-group">
                                    <select
                                        name="addressMunicipality"
                                        value={municipality}
                                        onChange={(e): void => setMunicipality(e.target.value)}
                                        required
                                    >
                                        <option>Municipio</option>
                                        {municipalities.map(item => (
                                            <option value={item.value} key={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-10">
                                <div className="input-group">
                                    <label htmlFor="">Dirección</label>
                                    <input type="text" name="addressPostal" required />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="input-group">
                                    <label htmlFor="">Número</label>
                                    <input type="text" name="addressNumber" required />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="input-group">
                                    <label htmlFor="">
                                        Bloque, escalera, piso, letra, número de finca...{' '}
                                        <span>Opcional</span>
                                    </label>
                                    <input type="text" name="addressBuilding" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="input-group">
                                    <label htmlFor="">
                                        Otros datos de localización <span>Opcional</span>
                                    </label>
                                    <textarea rows={5} name="addressAdditional" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div>
                                    <input
                                        className="input-checkbox"
                                        type="checkbox"
                                        id="checkbox1"
                                        name="agreementEula"
                                        required
                                    />
                                    <label htmlFor="checkbox1">
                                        Estoy de acuerdo con las{' '}
                                        <a href="docs/Condiciones Generales.pdf" target="_blank">
                                            La política de venta y cancelación/reembolso
                                        </a>
                                        ,{' '}
                                        <a
                                            href="docs/Política de protección de datos.pdf"
                                            target="_blank"
                                        >
                                            Política de protección de datos
                                        </a>
                                        ,{' '}
                                        <a href="docs/Aviso legal.pdf" target="_blank">
                                            AVISO LEGAL
                                        </a>
                                    </label>
                                </div>

                                <div>
                                    <input
                                        className="input-checkbox"
                                        type="checkbox"
                                        id="checkbox2"
                                        onChange={onCheckbox2}
                                        name="addRusTranslation"
                                    />
                                    <label htmlFor="checkbox2">
                                        Añadir traducción al Ruso (La traducción de las Notas
                                        simples al Ruso es realizada por traductores profesionales
                                        familiarizados con la terminología jurídica y registral, a
                                        fin de trasladar al ruso de la forma más precisa posible el
                                        contenido del documento original expedido por el Registro de
                                        la Propiedad en España.) Puede solicitar la traduccion de la
                                        nota simple a Ruso con un coste añadido{' '}
                                        <span className="text-blue">
                                            de 70€ + IVA 21% (en total 84,7 euro)
                                        </span>
                                    </label>
                                </div>

                                <div>
                                    <input
                                        className="input-checkbox"
                                        type="checkbox"
                                        id="checkbox3"
                                        name="agreementRusTranslationService"
                                    />
                                    <label htmlFor="checkbox3">
                                        Acepto los{' '}
                                        <a href="docs/Términos y condiciones.pdf" target="_blank">
                                            Términos y Condiciones de uso del servicio de traducción
                                            a Ruso
                                        </a>
                                    </label>
                                </div>

                                <div>
                                    <input
                                        className="input-checkbox"
                                        type="checkbox"
                                        id="checkbox4"
                                        onChange={onCheckbox4}
                                        name="addEngTranslation"
                                    />
                                    <label htmlFor="checkbox4">
                                        Añadir traducción al Ingles
                                        <br />
                                        (La traducción de las Notas simples al Ingles es realizada
                                        por traductores profesionales familiarizados con la
                                        terminología jurídica y registral, a fin de trasladar al
                                        inglés de la forma más precisa posible el contenido del
                                        documento original expedido por el Registro de la Propiedad
                                        en España.) Puede solicitar la traduccion de la nota simple
                                        al inglés con un coste añadido{' '}
                                        <span className="text-blue">
                                            de 50€ + IVA 21% (en total 60,5 euro)
                                        </span>
                                    </label>
                                </div>

                                <div>
                                    <input
                                        className="input-checkbox"
                                        type="checkbox"
                                        id="checkbox5"
                                        name="agreementEngTranslationService"
                                    />
                                    <label htmlFor="checkbox5">
                                        Acepto los{' '}
                                        <a href="docs/Términos y condiciones.pdf" target="_blank">
                                            Términos y Condiciones de uso del servicio de traducción
                                            al inglés
                                        </a>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="modal-total">
                            <div className="modal-title">NOTA SIMPLE - 25 € + IVA (21%)</div>

                            {checkbox2 && !checkbox4 && (
                                <>
                                    <div className="modal-title">70€ + IVA 21%</div>
                                    <div className="modal-title">Suma: 95€ + IVA 21%</div>
                                </>
                            )}

                            {!checkbox2 && checkbox4 && (
                                <>
                                    <div className="modal-title">50€ + IVA 21%</div>
                                    <div className="modal-title">Suma: 75€ + IVA 21%</div>
                                </>
                            )}

                            {checkbox2 && checkbox4 && (
                                <>
                                    <div className="modal-title">120€ + IVA 21%</div>
                                    <div className="modal-title">Suma: 145€ + IVA 21%</div>
                                </>
                            )}
                        </div>

                        <div className="modal-subtitle text-center">
                            Entrega a su correo electrónico en 48 horas después del pago, días
                            festivos y los fines de semana no incluidos
                        </div>

                        <div className="text-center">
                            <button className="rb btn btn-default btn-shadow" type="submit">
                                Ir al pago
                            </button>
                        </div>
                    </div>
                </form>
            </Modal>
            <div className="text-center">
                <button
                    type="button"
                    className="rb btn btn-default btn-shadow"
                    onClick={(): void => store.ui.toggleServiceRequestModal()}
                >
                    Solicitar
                </button>
            </div>
        </>
    ));
};

export default ServiceRequest;
