import { action, computed, observable, toJS } from 'mobx';

interface Image {
    url: string;
    name: string;
    description: string;
    position: ('placement' | 'facade')[];
}

class UiStore {
    @observable public galleryModalShowed = false;

    @observable public contactUsModalShowed = false;

    @observable public serviceRequestModalShowed = false;

    @observable public images: Image[] = [
        {
            url: 'img/top-slider/mainSlider.png',
            name: '',
            description: 'Opportunity_Global',
            position: ['placement']
        }
    ];

    @observable public currentSliderImageIdx = 0;

    @observable public galleryImages: Image[] = this.images;

    @computed public get facadeImages(): Image[] {
        return Object.entries(toJS(this.images))
            .filter(img => img[1].position.includes('facade'))
            .map(img => img[1]);
    }

    @computed public get placementImages(): Image[] {
        return Object.entries(toJS(this.images))
            .filter(img => img[1].position.includes('placement'))
            .map(img => img[1]);
    }

    @action('Toggle contact us modal') public toggleContactUsModal() {
        this.contactUsModalShowed = !this.contactUsModalShowed;
    }

    @action('Toggle request service modal') public toggleServiceRequestModal() {
        this.serviceRequestModalShowed = !this.serviceRequestModalShowed;
    }

    @action('Toggle gallery modal') public toggleGalleryModal(
        status: boolean | null,
        type: 'facade' | 'placement' | null,
        imgUrl?: string
    ): void {
        const state = status !== undefined && status !== null ? status : !this.galleryModalShowed;
        if (type) {
            this.galleryImages = type === 'facade' ? this.facadeImages : this.placementImages;
        }
        if (imgUrl) {
            for (const img of this.galleryImages) {
                if (img.url === imgUrl) {
                    this.currentSliderImageIdx = this.galleryImages.indexOf(img);
                }
            }
        } else this.currentSliderImageIdx = 0;

        this.galleryModalShowed = state;
    }
}

export default new UiStore();
