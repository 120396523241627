import React, { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import ClickOutHandler from 'react-onclickout';
import classNames from 'classnames';

interface LanguageSelectorProps
    extends Omit<React.HTMLProps<HTMLElement>, 'selected' | 'onSelect'> {
    items: { value: string; id?: string }[];
    selected: null | string;
    placeholder: string;
    onSelect: (item: string) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = (props: LanguageSelectorProps) => {
    const { items, selected, placeholder, className } = props;
    const [opened, setOpened] = useState(false);
    const listRef = useRef<HTMLUListElement>(null);

    const handleToggle = (status?: boolean): void => {
        setOpened(status !== undefined ? status : !opened);
    };

    const handleSelect = (item: string): void => {
        const { onSelect } = props;
        onSelect(item);
        handleToggle(false);
    };

    return (
        <div
            className={classNames(
                'language-selector',
                className,
                { open: opened },
                { active: selected }
            )}
            onMouseLeave={(): void => handleToggle(false)}
        >
            <div onClick={(): void => handleToggle(true)}>
                {selected || placeholder}
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    className="language-selector__str"
                    viewBox="0 0 8 13"
                    xmlSpace="preserve"
                >
                    <path d="M1.1,0L0,1l5.9,5.5L0,12l1.1,1L8,6.5L1.1,0z" />
                </svg>
            </div>
            <CSSTransition timeout={200} classNames="rtg-ft" unmountOnExit in={opened}>
                <ClickOutHandler onClickOut={(): void => handleToggle(false)}>
                    <ul onMouseLeave={(): void => handleToggle(false)} ref={listRef}>
                        {items.map(item => (
                            <li
                                key={item.id || item.value}
                                className={classNames({ active: item.value === selected })}
                                onClick={(): void => handleSelect(item.id || item.value)}
                            >
                                {item.value}
                            </li>
                        ))}
                    </ul>
                </ClickOutHandler>
            </CSSTransition>
        </div>
    );
};

export default LanguageSelector;
