const overviewBlock = document.querySelector('.assets-under-management__block.overview');
const summaryBlock = document.querySelector('.assets-under-management__block.summary');
const overviewButton = document.querySelector('.aum-overview');
const summaryButton = document.querySelector('.aum-summary');

if (overviewBlock && summaryBlock && overviewButton && summaryButton) {
    const showBlock = (block: 'summary' | 'overview'): void => {
        overviewButton.classList.remove('active');
        summaryButton.classList.remove('active');
        overviewBlock.classList.remove('active');
        summaryBlock.classList.remove('active');

        if (block === 'summary') {
            summaryButton.classList.add('active');
            summaryBlock.classList.add('active');
        } else {
            overviewButton.classList.add('active');
            overviewBlock.classList.add('active');
        }
    };

    summaryButton.addEventListener('click', (): void => showBlock('summary'));
    overviewButton.addEventListener('click', (): void => showBlock('overview'));
}

export default null;
