import React, { useState } from 'react';
import store from 'store';
import { useObserver } from 'mobx-react';

const RealEstate: React.FC = () => {
    const [minVariant, setMinVariant] = useState<boolean>(true);

    const handleClick = (url: string): void => {
        store.ui.toggleGalleryModal(true, 'facade', url);
    };

    const handleGoFull = (): void => setMinVariant(false);

    const handleShowreel = (): void => {
        store.ui.toggleGalleryModal(true, 'facade');
    };

    const getShowreelButton = (imgSrc: string): JSX.Element => {
        return (
            <div className="real-estate__item real-estate__item-1x" key={`real-estate-${imgSrc}`}>
                <button type="button" className="rb real-estate__button" onClick={handleShowreel}>
                    <img src="img/play-button.svg" />
                    Watch Showreel
                </button>
                <div
                    className="real-estate__item-image"
                    style={{ backgroundImage: `url(${imgSrc})` }}
                />
            </div>
        );
    };

    const getX = (idx: number): string => {
        const oneFrItems: number[] = [1, 2, 4, 5, 6];
        const twoFrItems: number[] = [0, 3];

        const frClasses: string[] = ['real-estate__item-1x', 'real-estate__item-2x'];

        if (oneFrItems.includes(idx)) return 'real-estate__item-1x';
        if (twoFrItems.includes(idx)) return 'real-estate__item-2x';
        return frClasses[Math.floor(Math.random() * 2)];
    };

    const getImages = useObserver(() => {
        const initialImages = [...store.ui.facadeImages];
        if (minVariant) initialImages.length = 7;
        return initialImages;
    });

    return useObserver(() => (
        <div className="container">
            <div className="real-estate__block">
                {getImages.map((img, idx) => {
                    if (getImages.length === idx + 1) return getShowreelButton(img.url);
                    return (
                        <div
                            key={`real-estate-${img.url}`}
                            role="button"
                            className={`real-estate__item ${getX(idx)}`}
                            onClick={(): void => handleClick(img.url)}
                        >
                            <div
                                className="real-estate__item-image"
                                style={{ backgroundImage: `url(${img.url})` }}
                            />
                            <div className="real-estate__item-hover">
                                <span>{img.name}</span>
                                <div>{img.description}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <br />
            {minVariant && (
                <div className="text-center">
                    <button className="btn btn-default" type="button" onClick={handleGoFull}>
                        More properties
                    </button>
                </div>
            )}
        </div>
    ));
};

export default RealEstate;
